@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 1.2rem;
}

@layer base {
  body {
    font-family: "Crimson Text", serif;
    background-color: white;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(static/fonts/Nunito-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(static/fonts/Nunito-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(static/fonts/Nunito-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'PT Serif';
  src: local('PT Serif'), url(static/fonts/PTSerif-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Serif';
  src: local('PT Serif'), url(static/fonts/PTSerif-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Serif';
  src: local('PT Serif'), url(static/fonts/PTSerif-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'PT Serif';
  src: local('PT Serif'), url(static/fonts/PTSerif-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson Text';
  src: local('Crimson Text'), url(static/fonts/CrimsonText-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}
